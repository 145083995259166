import React from 'react';

const jobsList = props => {
  const { department } = props;

  const jobList = department.jobs.map(job => {
    if (!job.hidden) {
      return (
        <a
          key={job.id}
          href={job.absolute_url}
          target="_blank"
          rel="noopener noreferrer"
          className={job.hidden ? 'c-careers-job c-careers-job--hidden' : 'c-careers-job'}
        >
          <div className="c-careers-job__name">
            {job.title.length < 60 ? job.title : job.title.substring(0, 58) + '...'}
          </div>
          <div className="c-careers-job__location">{job.location.name}</div>
        </a>
      );
    }
  });

  return <div>{jobList}</div>;
};

export default jobsList;
