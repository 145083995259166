import React, { useRef } from 'react';
import Select from 'react-select';

const Filters = props => {
  const { offices, departments, state, change, clearFilters } = props;

  const inputRef = useRef();

  const officeList = offices.map(el => {
    return {
      label: el.name,
      value: el.name,
      type: 'office'
    };
  });

  const departmentList = departments.map(el => {
    if (el) {
      return {
        label: el.name,
        value: el.name,
        type: 'department'
      };
    } else {
      return false;
    }
  });

  const { selectedOption } = state;

  return (
    <div className="c-careers-filters">
      <div className="c-careers-filters__cont">
        <input
          ref={inputRef}
          className="c-careers-filters__item c-careers-filters__item--search"
          type="text"
          placeholder="Position"
          onChange={change}
        />
      </div>
      <div className="c-careers-filters__cont c-careers-filters__cont--select">
        <Select
          className="c-careers-filters__item c-careers-filters__item--office"
          classNamePrefix="c-careers-filters__select"
          isSearchable
          placeholder="Office"
          value={state.filters.current.type === 'office' ? selectedOption : null}
          onChange={change}
          options={officeList}
        />

        <Select
          className="c-careers-filters__item c-careers-filters__item--department"
          classNamePrefix="c-careers-filters__select"
          isSearchable
          placeholder="Team"
          value={state.filters.current.type === 'department' ? selectedOption : null}
          onChange={change}
          options={departmentList}
        />
      </div>
      {state.filters.current.value && (
        <button
          className="c-careers-filters__clear-btn"
          onClick={() => {
            inputRef.current.value = '';
            clearFilters();
          }}
        >
          Clear Filters
        </button>
      )}
    </div>
  );
};

export default Filters;
