import React, { Component } from 'react';
import axios from 'axios';
import Filters from './Filters/Filters';
import Department from './Department/Department';
import Masonry from 'react-masonry-css';

class Jobs extends Component {
	endpointID = 'hearcom';
	defaultState = {
		jobs: [],
		departments: [],
		offices: [],
		filters: {
			current: {
				type: null,
				value: null
			},
			office: {
				selected: null
			},
			department: {
				selected: null
			},
			change: {
				selected: null
			}
		}
	};

	state = this.defaultState;

	componentDidMount() {
		this.getJobsByDep();
	}

	// Asynchronous call to fetch data from greenhouse
	getJobsByDep = () => {
		axios.get(`https://boards-api.greenhouse.io/v1/boards/${this.endpointID}/departments`).then((result) => {
			this.setState({ jobs: result.data.departments });
			this.setState({ offices: this.getOfficesFromDeps(result.data) });
			this.setState({
				departments: result.data.departments
					.filter((el) => el.jobs.length > 0 && el.id !== 0)
					.map((el) => ({
						name: el.name,
						id: el.id
					}))
			});
		});
	};

	getOfficesFromDeps = (data) => {
		return data.departments
			.map((value) => value.jobs.map((child) => child.location.name))
			.flat()
			.filter((a, b, array) => array.indexOf(a) === b) // filter out dupes
			.map((item) => ({ name: item }));
	};

	filterJobs = (filter) => {
		let jobs = [...this.state.jobs];

		jobs.map((dep, index) => {
			let visibleJobs = 0;
			dep.jobs.map((job, i) => {
				dep.hidden = false;
				let selectedFilter = this.state.filters[filter].selected;
				if (selectedFilter) {
					if (filter === 'office') {
						job.hidden = false;
						if (selectedFilter !== job.location.name) {
							job.hidden = true;
						} else {
							visibleJobs += 1;
						}
					} else if (filter === 'department') {
						job.hidden = false;
						if (selectedFilter !== dep.name) {
							dep.hidden = true;
						}
					} else if (filter === 'change' && this.props.query) {
						//this part if used for the FuterTalents page
						job.hidden = true;
						let searchInput = selectedFilter.split(' ').filter((i) => i);
						searchInput.forEach((item) => {
							if (job.title.toLowerCase().includes(item.toLowerCase())) {
								job.hidden = false;
								visibleJobs += 1;
							}
						});
					} else if (filter === 'change') {
						job.hidden = false;
						if (!job.title.toLowerCase().includes(selectedFilter.toLowerCase())) {
							job.hidden = true;
						} else {
							visibleJobs += 1;
						}
					}

					this.setState((prevState) => ({
						...prevState,
						filters: {
							...prevState.filters,
							current: {
								...prevState.filters.current,
								type: filter,
								value: selectedFilter
							}
						}
					}));
				} else {
					dep.hidden = false;
				}
				if (filter === 'change' && selectedFilter) {
					dep.hidden = visibleJobs < 1 ? true : false;
				}
			});

			if (filter === 'office') {
				dep.hidden = visibleJobs < 1 ? true : false;
			}
		});

		this.setState({ jobs: jobs });
	};

	clearFilters = () => {
		this.setState({
			filters: this.defaultState.filters
		});

		const defaultJobs = this.state.jobs.map((job) => {
			job.hidden = false;
			job.jobs.forEach((dep) => {
				dep.hidden = false;
			});
			return job;
		});

		this.setState({
			jobs: defaultJobs
		});
	};

	handleChange = (event) => {
		const filter = event.type;
		let filters = this.state.filters;
		filters.office.selected = null;
		filters.department.selected = null;
		filters[filter].selected = filter === 'change' ? event.target.value : event.value;
		filters.filtered = true;
		this.setState({ filters: filters });

		this.filterJobs(filter);
	};

	render() {
		const lang = 'en';
		const departments = this.state.jobs.map((dep) => {
			return <Department key={dep.id} id={dep.id} name={dep.name} department={dep} />;
		});
		const breakpointColumnsObj = {
			default: 2,
			960: 1
		};

		return (
			<div className="u-font-sans">
				<h2 className="c-md-h2" style={{ textAlign: 'center' }}>
					Positions
				</h2>
				<Filters
					offices={this.state.offices}
					departments={this.state.departments}
					filters={this.state.filters}
					state={this.state}
					change={this.handleChange}
					lang={lang}
					clearFilters={this.clearFilters}
				/>
				<Masonry
					breakpointCols={this.props.query ? 1 : breakpointColumnsObj}
					className="c-careers-jobs__container"
					columnClassName="c-careers-jobs__column"
				>
					{departments}
				</Masonry>
			</div>
		);
	}
}

export default Jobs;
